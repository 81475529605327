import React, { useState, useEffect } from 'react';
import axios from 'axios';

function PostList() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('https://david.artunlimiteddev.com/wp-json/wp/v2/posts/?_embed')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
<div className='site-content'>
  <div className='ast-container'>
    <div className='content-area primary'>
      <main className='site-main'>
        {posts.map(post => (
        <article key={post.id} className='post category-website-development ast-grid-common-col ast-full-width ast-article-post'>
            <div className='ast-blog-featured-section post-thumb ast-grid-common-col ast-float'>
              <div className='post-thumb-img-content post-thumb'>
                <img width='1024' height='535' src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
              </div>
            </div>
            <h2 className='entry-title' dangerouslySetInnerHTML={{ __html: post.title.rendered}}></h2>
          <p>{post._embedded['wp:term'][0].map(category => (
          <span key={category.id}>{category.name}</span>
          ))}</p>
          <p>By {post._embedded.author[0].name}</p>
          <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered}}></p>
          <a href={`/posts/${post.slug}`}>Read more</a>
        </article>
        ))}
      </main>
    </div>
  </div>
</div>
  );
}

export default PostList;