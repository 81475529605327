import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
import PostList from './components/PostList';
import Post from './components/Post';
import Page from './components/Page';
import Menu from './Menu';

function App() {
  const [menuData, setMenuData] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    axios
      .get('https://david.artunlimiteddev.com/wp-json/menus/v1/menus/main-menu')
      .then(response => {
        setMenuData(response.data.items);
      })
      .catch(error => {
        console.log(error);
      });

    axios
      .get('https://david.artunlimiteddev.com/wp-json/wp/v2/pages')
      .then(response => {
        setPageData(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const renderRoutes = () => {
    const routes = [];

    menuData.forEach(menuItem => {
      if (menuItem.child_items && menuItem.child_items.length > 0) {
        menuItem.child_items.forEach(childItem => {
          const { url, object } = childItem;
          const slug = getSlugFromUrl(url);
          const routePath = `/${slug}`;

          if (object === 'page') {
            routes.push(
              <Route
                key={slug}
                path={routePath}
                render={() => <Page slug={slug} />}
              />
            );
          }
        });
      }
    });

    return routes;
  };

  const getSlugFromUrl = url => {
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 2];
  };

  return (
    <Router>
      <div>
        <Menu />
        <Switch>
          <Route exact path="/" component={PostList} />
          <Route path="/posts/:slug" component={Post} />
          <Route
            path="/services/:slug"
            render={({ match }) => <Page slug={match.params.slug} />}
          />
          <Route
            path="/about-us/:slug"
            render={({ match }) => <Page slug={match.params.slug} />}
          />
          {renderRoutes()}
          {pageData.map(page => (
            <Route
              key={page.id}
              path={`/${page.slug}`}
              render={() => <Page slug={page.slug} />}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
