import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Page({ slug }) {
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    axios.get(`https://david.artunlimiteddev.com/wp-json/wp/v2/pages?slug=${slug}`)
      .then(response => {
        console.log(response.data); // Log the response data for debugging
        setPageContent(response.data[0].content.rendered);
      })
      .catch(error => {
        console.log(error);
      });
  }, [slug]);

  console.log(pageContent); // Log the page content for debugging

  return (
    <div className='site-content'>
      <div className='ast-container'>
        <div className='content-area primary'>
          <main className='site-main'>
            <article>
              <div dangerouslySetInnerHTML={{ __html: pageContent }}></div>
            </article>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Page;
