import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Post(props) {
  const [post, setPost] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);

  useEffect(() => {
    const slug = props.match.params.slug;
    axios.get(`https://david.artunlimiteddev.com/wp-json/wp/v2/posts?_embed&slug=${slug}`)
      .then(response => {
        setPost(response.data[0]);

        // set the featured image if it exists
        if (response.data[0]._embedded['wp:featuredmedia'] !== undefined) {
          setFeaturedImage(response.data[0]._embedded['wp:featuredmedia'][0].source_url);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [props.match.params.slug]);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className='show'>
      <div className='site-content'>
  <div className='ast-container'>
    <div className='content-area primary'>
      <main className='site-main'>
        <article key={post.id} className='post type-post status-publish format-standard has-post-thumbnail hentry category-website-development ast-article-single'>
			<div className='ast-post-format- single-layout-1'>
				<header className='entry-header'>
					<div className='ast-blog-featured-section post-thumb ast-grid-common-col ast-float'>
						<h1 dangerouslySetInnerHTML={{ __html: post.title.rendered}} className='entry-title'></h1>
							{featuredImage ? (
							  <div>
								<img width='1024' height='535' src={featuredImage} alt={post.title.rendered} style={{ display: 'block', width: '100%'}}/><br></br>
							  </div>
							) : null}
					</div>
				</header>
			</div>
          <div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
        </article>
      </main>
    </div>
  </div>
</div>
    </div>
  );
}

export default Post;
