import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './menu.css';

function Menu() {
  const [menuData, setMenuData] = useState([]);

  useEffect(() => {
    axios
      .get('https://david.artunlimiteddev.com/wp-json/menus/v1/menus/main-menu')
      .then(response => {
        setMenuData(response.data.items);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  const renderMenuItems = menuItems => {
    return menuItems.map(menuItem => {
      const { url, title, child_items } = menuItem;
      const linkUrl = url.replace(
        'https://david.artunlimiteddev.com',
        ''
      );

      if (child_items && child_items.length > 0) {
        return (
          <li key={menuItem.ID}>
            <a href={linkUrl}>{title}</a>
            <ul className="sub-menu">
              {renderMenuItems(child_items)}
            </ul>
          </li>
        );
      } else {
        return (
          <li key={menuItem.ID}>
            <a href={linkUrl}>{title}</a>
          </li>
        );
      }
    });
  };

  return (
    <nav>
      <ul className="main-menu">
        {renderMenuItems(menuData)}
      </ul>
    </nav>
  );
}

export default Menu;
